<template>
  <div class="excel-upload">
    <h3> {{ $t('excelUpload.uploadTitle') }}</h3>
    <el-upload
      ref="fileUploader"
      v-loading="loading"
      class="upload-container"
      action=""
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :auto-upload="false"
      :on-change="handleChange"
      accept=".csv,.xlsx,.ods"
      drag
    >
      <!-- :show-file-list="false" -->
      <em class="el-icon-upload" />
      <div>
        <el-button
          type="success"
          plain
        >
          {{ $t('File.ChooseFile') }}
        </el-button>
        <div class="el-upload__text">{{ $t('File.DragFilesHere') }}</div>
        <div class="upload-tips-container">
          <div>{{ $t('File.MaxFileSize') }}: {{ $t('File.FileSizeLimit') }}</div>
          <div>{{ $t('File.AllowedFileTypes') }}: {{ fileTypeLimit }}</div>
        </div>
      </div>
      <template v-if="uploadForm.uploadFileList.length">
        <el-button
          type="success"
          plain
          @click.stop="uploadCollection"
        >
          {{ $t('File.Upload') }}
        </el-button>
      </template>
    </el-upload>
    <template v-if="hasMessage">
      <div class="uploadfeedback-container">
        <el-button
          type="success"
          plain
          @click="dialogVisible = true"
        >
          {{ fileName }} {{ $t('excelUpload.checkErrorMessage') }}
        </el-button>
        <el-button class="btn-copy" type="success" plain>
          <router-link :to="{name:'Data'}"> {{ $t('navbar.DataList') }} </router-link>
        </el-button>
        <el-tooltip class="item" effect="light" content="Copy json" placement="top-start">
          <el-button class="btn-copy" type="success" plain circle @click="copy">
            <i class="el-icon-document-copy" />
          </el-button>
        </el-tooltip>
      </div>
    </template>
    <div class="checkexcelfirst-container">
      * [{{ $t('excelUpload.Important') }}]
      {{ $t('excelUpload.checkBeforeUpload_1') }}
      <span>
        <router-link :to="{name:'CheckExcelUpload'}"> {{ $t('navbar.CheckFile') }} </router-link>
        <i class="el-icon-link" />
      </span>
      {{ $t('excelUpload.checkBeforeUpload_2') }}
    </div>
    <ErrorDialog
      v-if="isError"
      :dialog-visible="dialogVisible"
      :error-code="errorCode"
      :error-field-list="errorFieldList"
      @closeDialog="emitCloseDialog"
    />
    <SuccessDialog
      v-else-if="isSuccess"
      :dialog-visible="dialogVisible"
      :statistics-data="statisticsData"
      :count-total="countTotal"
      :count-success="countSuccess"
      :count-missing="countMissing"
      :count-duplicate="countDuplicate"
      :duplicated-data="duplicatedData"
      :missing-required-data="missingRequiredData"
      :change-file="changeFile"
      :is-test="isTest"
      @closeDialog="emitCloseDialog"
    />
  </div>
</template>

<script>
import { containSpecialChar, fileSizeToMB } from '@/utils/extension'
import ErrorDialog from './components/ErrorDialog'
import SuccessDialog from './components/SuccessDialog'
import { SuccessMessage } from '@/Mixins'
import { uploadExcel } from '@/api/excel'

export default {
  name: 'ExcelUpload',
  components: {
    ErrorDialog,
    SuccessDialog
  },
  mixins: [SuccessMessage],
  data () {
    return {
      uploadForm: {
        uploadFileList: []
      },
      fileTypeLimit: 'Excel, CSV, ods',
      fileName: '',
      // version: '1',
      dialogVisible: false,
      isError: false,
      isSuccess: false,
      hasMessage: false,
      errorCode: 0,
      errorFieldList: [],
      statisticsData: {},
      countTotal: 0,
      countSuccess: 0,
      countMissing: 0,
      countDuplicate: 0,
      duplicatedData: {},
      missingRequiredData: {},
      changeFile: false,
      isTest: false,
      copyData: '',
      loading: false
    }
  },
  methods: {
    async handleChange(file, fileList) {
      const { size, name } = file

      // for single upload
      if (fileList.length > 1) {
        fileList.splice(0, 1)
        this.handleRemove()
      }

      if (fileSizeToMB(size) > 20) {
        this.$message({
          title: this.$t('general.Warning'),
          message: this.$t('Notify.FileSizeLimit', { size: this.fileSizeLimit }),
          type: 'warning'
        })
        this.resetUpload()
      } else if (containSpecialChar(name)) {
        this.$message({
          title: this.$t('general.Warning'),
          message: this.$t('Notify.FileNameLimit'),
          type: 'warning'
        })
        this.resetUpload()
      } else {
        this.uploadForm.uploadFileList = fileList
      }
    },
    // 點擊上傳按鈕觸發
    uploadCollection() {
      const fileList = this.uploadForm.uploadFileList
      this.uploadFiles(fileList)
      this.fileName = fileList[0].name
    },
    async uploadFiles(fileList) {
      if (!fileList) return
      // this.listLoading = true
      // use one by one edit issue to upload file
      this.loading = true
      const _this = this
      try {
        const uploadApi = fileList.map(function (item) {
          const sendForm = new FormData()
          sendForm.delete('file')
          sendForm.append('file', item.raw)
          // sendForm.append('version', _this.version)
          return uploadExcel(sendForm)
        })
        const apiResult = await Promise.allSettled(uploadApi)
        apiResult.forEach((item) => {
          if (item.status === 'rejected') {
            const apiErrorData = item.reason.response.data.error
            this.copyData = item.reason.response.data
            this.isError = true
            this.errorCode = apiErrorData.code

            // 除了errorCode外, 有其他資訊的
            const hasErrorFieldList = [4001, 4002, 4003]
            if (hasErrorFieldList.find(el => el === this.errorCode)) {
              this.errorFieldList = apiErrorData.details.field_list
            }
          } else {
            const apiSuccessData = item.value.data
            this.copyData = item.value.data
            this.isSuccess = true
            this.countTotal = apiSuccessData.statistics.total
            this.countSuccess = apiSuccessData.statistics.success_num
            this.countMissing = apiSuccessData.statistics.missing_required_fields_nums
            this.countDuplicate = apiSuccessData.statistics.duplicate_rows_num
            this.missingRequiredData = apiSuccessData.detail.fail.missing_required_fields
            this.duplicatedData = apiSuccessData.detail.fail.duplicated_rows
          }
          this.loading = false
          this.hasMessage = true
          this.dialogVisible = true
        })
      } catch (err) {
        console.log('err', err.message)
      }
    },
    emitCloseDialog() {
      this.dialogVisible = false
    },
    resetUpload() {
      this.$refs.fileUploader.clearFiles()
      this.uploadForm.uploadFileList = []
    },
    handlePreview() {},
    handleRemove(file, fileList) {
      this.hasMessage = false
      this.copyData = ''
      this.initErrorDialog()
      this.initSuccessDialog()
    },
    initErrorDialog() {
      this.isError = false
      this.errorCode = 0
      this.errorFieldList = []
    },
    initSuccessDialog() {
      this.changeFile = true
      this.isSuccess = false
      this.countTotal = 0
      this.countSuccess = 0
      this.countMissing = 0
      this.countDuplicate = 0
      this.duplicatedData = {}
      this.missingRequiredData = {}
    },
    copy() {
      const copyData = JSON.stringify(this.copyData)

      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyData)
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = copyData
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej
          textArea.remove()
        })
      }
      const message = this.$t('general.Copy')
      this.showSuccessMessage(message)
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/index.scss";

.excel-upload {
  width: 100%;
  padding-top: 40px;
  margin: 0 auto;
  h3{
    margin: 0;
    text-align: center;
  }
  .upload-container{
    width: 75%;
    margin: 0 auto;
    .el-upload {
      width: 100%;
      .el-upload-dragger {
        height: 90%;
        width: 100%;
        padding: 60px;

        .el-icon-upload {
          font-size: 137px;
          margin: 50px;
        }

        .el-button {
          padding: 10px 50px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .upload-tips-container {
    font-size: 12px;
    color: $text;
    margin: 15px 0;
  }
  /* reset element-ui css */

  .checkexcelfirst-container, .uploadfeedback-container{
    width: 75%;
    margin: 0 auto;
    padding-top: 20px;
    font-size: 13px;
    text-align: left;

    a, i{
      color: $primary;
    }
  }
}

</style>
